import './pm2.css';

function Pm2() {
  return (
    <div className="pm2">
      <iframe src="https://app.pm2.io" title="PM2" />
    </div>
  );
}

export default Pm2;
