import './header.css'
import React from 'react';
import Config from '../config.js';
import { invalidateSession } from '@harxer/session-manager-lib';
import { CONTENT_DISPLAYS as Content } from '../javascript/constants';
import PropTypes from 'prop-types';

class Header extends React.Component {
  // constructor(props) {
  //   super(props)
  // }

  contentButton(type) {
    return (
      <p className={this.props.sectionSelected === type ? 'selected' : ''}
        onClick={()=>this.props.handleSectionSelected(type)}>
        {type}
      </p>
    )
  }

  navigationButtons() {
    return(
      <React.Fragment>
        <p className="home">HB</p>

        {this.contentButton(Content.LOGS)}
        {this.contentButton(Content.PM2)}
        {this.contentButton(Content.TOKEN)}

        <p onClick={()=>this.logout()}>Logout</p>
      </React.Fragment>
    )
  }

  logout() {
    invalidateSession(Config.url.auth).then(_ => {
      window.location.reload(false);
    })
  }

  render() {
    return (
      <div className="header">
        {this.navigationButtons()}
      </div>
    );
  }
}

Header.defaultProps = {
  contentSections: []
}

Header.propTypes = {
  sectionSelected: PropTypes.string,
  handleSectionSelected: PropTypes.func
}

export default Header;
