import './logs.css';
import { logs } from '../../../javascript/HarxerWebServiceClient.js';
import { LOG_REQ_LIMIT } from '../../../javascript/constants';
import React from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import * as IpInfoServiceClient from '../../../javascript/IpInfoServiceClient';

mapboxgl.accessToken = 'pk.eyJ1IjoiaGFyeGVyIiwiYSI6ImNreHIzajhidTRqNjUydXB6c2VudW52MmEifQ.y85HVJazQfawczurGJI2qw';

class Logs extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      coordinates: [],
      logs: [],
      logsPage: 0,
      logsPageMax: 0
    }

    this.mapContainer = React.createRef()
    this.map = undefined // TODO - review this usage

    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
  }

  componentDidMount() {
    this.map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: 'mapbox://styles/mapbox/light-v10',
      zoom: -1
    });
    this.map.addControl(new mapboxgl.NavigationControl());

    this.retrieveLogs()
  }

  retrieveLogs() {
    const { logsPage: page } = this.state

    logs(page).then(response => {
      this.setState({logs: response.logs, logsPageMax: this.calcPageMax(response.count)})
      // Clear old markers
      this.state.coordinates.forEach(coordinate => coordinate.remove())
      // Geolocate IPs
      Promise.all(
        response.logs.map(log => IpInfoServiceClient.geo(log.ip))
      ).then(coordinates => {
        this.setState({coordinates:
          coordinates.map(coordinate => {
            let lat = coordinate.loc.substring(0,coordinate.loc.indexOf(","))
            let long = coordinate.loc.substring(coordinate.loc.indexOf(",") + 1)
            let marker = new mapboxgl.Marker().setLngLat([long, lat]).addTo(this.map);
            // marker.bindPopup("<b>"+coordinate.ip+"</b><br>"+coordinate.city+","+coordinate.region+","+coordinate.country+"<br><i>"+coordinate.ip+"</i>")
            return marker
          })
        })
      }).catch(_ => {})
      
    }).catch(err => {
      if (err === "401" || err === "400") {
        window.location.reload(false);
      }
    }) 
  }

  calcPageMax = (elements) => parseInt(Math.ceil(elements / LOG_REQ_LIMIT))

  onLastPage = () => (this.state.logsPage + 1 >= this.state.logsPageMax)

  onFirstPage = () => (this.state.logsPage === 0)

  handleNext() {
    if (this.onLastPage()) return
    this.setState({logsPage: this.state.logsPage + 1}, this.retrieveLogs)
  }

  handlePrev() {
    if (this.onFirstPage()) return
    this.setState({logsPage: this.state.logsPage - 1}, this.retrieveLogs)
  }

  logEntries() {
    return this.state.logs.map((log, i) => 
      <li key={i}>
        <p className='index'>{(this.state.logsPageMax - this.state.logsPage) * LOG_REQ_LIMIT + (LOG_REQ_LIMIT - i)}</p>
        <p className='time'>{log.time}</p>
        <p className='ip'>{log.ip === undefined ? '--' : log.ip}</p>
        <p className='request'>{log.request}</p>
      </li>  
    )
  }

  render() {
    return (
      <div id='logs-container'>
        <div ref={this.mapContainer} id='map-box'></div>
        <ul className='logs'>
          {this.logEntries()}
        </ul>
        <div className='page-controls'>
          <button className='control-prev' disabled={this.onFirstPage()} onClick={this.handlePrev}>Previous</button>
          <button className='control-next' disabled={this.onLastPage()} onClick={this.handleNext}>Next</button>
        </div>
      </div>
    );
  }
}

export default Logs;
