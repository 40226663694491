
/**
 * Creates an XMLHttpRequest with the given method, url, and optional body
 * @param {string} method 
 * @param {string} url 
 * @param {string} body - OPTIONAL
 */
 export default function httpRequest(method, url, body, skipCredentials = false) {
  return new Promise((resolve, reject) =>  {
    let request = new XMLHttpRequest()
    request.withCredentials = !skipCredentials
    request.open(method, url)
    request.onerror = (err) => {
      reject(request.status)
    }
    request.onload = () => {
      if (request.status === 200) {
        try {
          resolve(JSON.parse(request.responseText))
        } catch(err) {
          resolve({})
        }
      } else {
        reject(request.status)
      }
    }
    if (body) {
      request.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
      request.send(JSON.stringify(body))
    } else {
      request.send()
    }
  })
}
