
export const CONTENT_DISPLAYS = {
  LOGS: "Logs",
  PM2: "PM2",
  TOKEN: "Token" 
}

export const LOG_REQ_LIMIT = 10

export const REQ_SKIP_CREDENTIALS = true
export const REQ_NO_BODY = undefined
