import HttpRequest from './HttpRequest'
import { REQ_NO_BODY, REQ_SKIP_CREDENTIALS } from './constants'

/**
 * ipinfo.io - max 150 request a day
 * @returns Promise
 */
 export function geo(ip) {
   if (ip === undefined || ip === "") {
    return Promise.reject('400') // Promise.resolve({loc: '18.5196,73.8554'}) 
   }
  return HttpRequest('GET', `https://ipinfo.io/${ip}/geo?token=fb91d65f3c5cb4`, REQ_NO_BODY, REQ_SKIP_CREDENTIALS)
}
