export default {
  "system": {
    "portfolio_site_name": "harrisonbalogh---portfolio-site"
  },
  "file_system": {
    "project_store": "/home/hb/project_store",
    "http": "/var/www/html",
    "secrets": "/home/hb/project_store/_secrets",
    "icons_cache": "/home/hb/project_store/_cache/project_icons",
    "environment": "/home/hb/project_store/_environments"
  },
  "url": {
    "auth": "https://www.harrisonbalogh.com",
    "db": "mongodb://localhost/contentdb",
    "api_base": "https://www.harrisonbalogh.com"
  }
}
